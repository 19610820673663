import {
  buildCriterionExpression,
  buildFieldSortCriterion,
  SortCriterion,
  SortOrder,
} from '@coveo/headless'
import { TSearchPageTab } from './SearchPage/types'
import React from 'react'

interface SortProps {
  sort: TSearchPageTab['sort']
}

const criteria: [string, SortCriterion][] = [
  ['Most Relevant', buildFieldSortCriterion('score', SortOrder.Ascending)],
  ['Most Recent', buildFieldSortCriterion('date', SortOrder.Descending)],
  ['Least Recent', buildFieldSortCriterion('date', SortOrder.Ascending)],
]

export default function Sort({ sort }: SortProps) {
  const { controller, state } = sort

  const getCriterionFromName = (name: string) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return criteria.find(([criterionName]) => criterionName === name)!
  }

  const value = React.useMemo(() => {
    const current = criteria.find(
      ([, criterion]) => state.sortCriteria === buildCriterionExpression(criterion)
    )

    return current ? current[0] : undefined
  }, [])

  return (
    <div>
      <label id="sort-label" className="text-sm text-gray-700 mr-1">
        Sort by
      </label>
      <select
        className="p-1 bg-transparent text-sm hover:bg-lumx-dark-L5 cursor-pointer"
        aria-labelledby="sort-label"
        value={value}
        onChange={(e) => controller.sortBy(getCriterionFromName(e.target.value)[1])}
      >
        {criteria.map(([criterionName]) => (
          <option key={criterionName} value={criterionName}>
            {criterionName}
          </option>
        ))}
      </select>
    </div>
  )
}
