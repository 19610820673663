import { useEffect } from 'react'
import {
  buildContext,
  buildSearchEngine,
  getOrganizationEndpoints,
  SearchEngine,
  buildResultList,
  ResultListState,
  buildFoldedResultList,
  FoldedResultListState,
  loadFieldActions,
} from '@coveo/headless'
import {
  ADC_CONFIG,
  API_KEY,
  INSTANCE_ID,
  LUMAPPS_FIELDS,
  SERVICE_CALLER,
  TOKEN_SERVICE_BASE_URL,
} from '../constants'
import {
  PlatformClientOrigin,
  PlatformRequestOptions,
  RequestMetadata,
} from '@coveo/headless/dist/definitions/api/preprocess-request'
import { getTagzFromQuery, isTagzQuery } from './tagz'

type SearchEngineWrapper = SearchEngine & { caller: string; uid: string }

declare global {
  interface Window {
    onSearchQueryChange: (text: string) => void
    SEARCH_INPUT?: { current: HTMLInputElement }
    ADC_SEARCH_INPUT?: { current: HTMLInputElement }
    ADC_HEADLESS?: { CoveoEngine?: SearchEngineWrapper; CoveoToken?: string; query?: string }
    ADCSearchInterface?: string
    IsADCLiveSearchEnabled?: boolean
    adcHeadlessSearch: any
    lumapps: any
  }
}

export type LumAppsTokenPayload = {
  organizationId: string
  aud: string // baseURL
  email: string
  iss: string
  iat: number
  exp: number
  sub: string // userID
  jti: string // guid, tokenID
  isOrgAdmin: boolean
}

type CoveoUserID = {
  type: string
  name: string
  provider: string
}

type CoveoTokenPayload = {
  organization: string
  searchHub: string
  userIds: CoveoUserID[]
  roles: string[]
  exp: number
  iat: number
}

function parseJWT(token: string) {
  const parts = token.split('.')
  if (parts.length !== 3) {
    return null
  }
  const base64Url = parts[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => {
        const charCode = c.charCodeAt(0)
        const hexString = charCode.toString(16)
        const paddedHexString = `00${hexString}`.slice(-2)
        return `%${paddedHexString}`
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

const exchangeAccessToken = (
  tokenServiceBaseUrl: string,
  token: string,
  setSearchToken: (searchToken: string) => void,
  usePost = false,
  queryParams?: string
) => {
  const headers = {
    'Content-Type': 'application/json',
  } as Record<string, string>

  if (!usePost) headers.Authorization = `Bearer ${token}`

  const qpArray = [] as string[]
  if (queryParams) qpArray.push(queryParams)
  const qp = qpArray.length > 0 ? `?${qpArray.join('&')}` : ''

  const body = usePost ? JSON.stringify({ token }) : undefined
  const uri = `${tokenServiceBaseUrl}/coveo/token${qp}`

  fetch(uri, {
    headers,
    method: usePost ? 'POST' : 'GET',
    body,
  })
    .then((response) => {
      if (response.ok) {
        response.json().then((json) => {
          setSearchToken(json.token)
        })
      }
    })
    .catch((error) => {
      console.log(error)
    })
}

const analyticsClientMiddleware = (eventName: string, payload: any) => {
  const newPayload = { ...payload, customData: { 'X-Referrer': window.location.href } }
  return newPayload
}

const getLumAppsUserInformation = async (lumappsApiBaseUrl: string, token: string) => {
  try {
    const response = await fetch(`${lumappsApiBaseUrl}/_ah/api/lumsites/v1/user/get`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    return await response.json()
  } catch (error) {
    console.log(error)
    return null
  }
}

export function useCoveoUserContext(engine: SearchEngine | null, user: any) {
  useEffect(() => setCoveoUserContext(engine, user), [engine, user])
}

const getContentSlug = () => {
  if (!window.INIT_URL) return ''
  try {
    return new URL(window.INIT_URL).searchParams.get('slug') || ''
  } catch (error) {
    console.error(window.INIT_URL, error)
    return ''
  }
}

const getCommunitySlug = () => {
  try {
    const communitySlug = window.location.pathname.includes('ls/community/')
      ? window.location.pathname.split('ls/community/')[1].split('/posts')[0]
      : ''
    return communitySlug
  } catch (error) {
    console.error(error)
    return ''
  }
}

function setCoveoUserContext(engine: SearchEngine | null, user: any) {
  if (engine && user) {
    const context = {
      email: user.email,
      region: user.apiProfile?.intuitWorkCountryISO, // 'US' instead of 'United States of America',
      intuitgroupdisplay: user.apiProfile?.intuitGroupDisplay,
      intuitempclass: user.apiProfile?.employeeType,
      intuitagenttype: user?.apiProfile?.intuitAgentType,
      intuitagentlob: user?.apiProfile?.intuitAgentLOB,
      intuitsupplier: user?.apiProfile?.intuitSupplier,
      intuitagentlocation: user?.apiProfile?.intuitAgentLocation,
      lumappsInstanceSlug: window.INSTANCE_SLUG,
      lumappsCustomerSlug: window.CUSTOMER_SLUG,
      lumappsCustomerId: window.CUSTOMER_ID,
      lumappsSiteId: INSTANCE_ID,
      referrer: window.location.href,
      applicationorigin: 'adc-headless-search',
      lumappsContentSlug: getContentSlug(),
      lumappsCommunitySlug: getCommunitySlug(),
    }
    const engineContext = buildContext(engine)
    engineContext.set(context)
  }
}

const getSharedCoveoToken = ({
  lumappsAccessToken,
  tokenServiceBaseUrl,
  setAccessToken,
  usePost = false,
  queryParams,
}: {
  lumappsAccessToken: string
  tokenServiceBaseUrl: string
  setAccessToken: (newToken: string) => void
  usePost: boolean
  queryParams?: string
}) => {
  if (window.ADC_HEADLESS && window.ADC_HEADLESS.CoveoToken) {
    setAccessToken(window.ADC_HEADLESS.CoveoToken)
  }
  const onTokenReady = (token: string) => {
    window.ADC_HEADLESS = {}
    window.ADC_HEADLESS.CoveoToken = token
    setAccessToken(token)
  }
  if (lumappsAccessToken)
    exchangeAccessToken(tokenServiceBaseUrl, lumappsAccessToken, onTokenReady, usePost, queryParams)
}

const getCoveoEngine = ({
  lumappsAccessToken,
  tokenServiceBaseUrl,
  setEngine,
  usePost = false,
  queryParams,
}: {
  lumappsAccessToken: string
  tokenServiceBaseUrl: string
  setEngine: (engine: SearchEngine) => void
  usePost: boolean
  queryParams?: string
}) => {
  const onTokenAvailable = (accessToken: string) => {
    const { aud: lumappsApiBaseUrl } = (
      lumappsAccessToken ? parseJWT(lumappsAccessToken) : {}
    ) as LumAppsTokenPayload
    const { organization: coveoOrganizationId } = (
      accessToken ? parseJWT(accessToken) : {}
    ) as CoveoTokenPayload
    getLumAppsUserInformation(lumappsApiBaseUrl, lumappsAccessToken).then((userData) => {
      const engine = buildSearchEngine({
        configuration: {
          accessToken,
          organizationId: coveoOrganizationId,
          organizationEndpoints: getOrganizationEndpoints(coveoOrganizationId),
          analytics: {
            enabled: true,
            analyticsClientMiddleware,
          },
          preprocessRequest: (
            request: PlatformRequestOptions,
            clientOrigin: PlatformClientOrigin,
            metadata?: RequestMetadata
          ) => {
            if (metadata?.method === 'search' && clientOrigin === 'searchApiFetch') {
              const body = JSON.parse(request.body?.toString() || '')

              if (isTagzQuery(body.q)) {
                const tag = getTagzFromQuery(body.q)
                body.q = `@lumapps_tagz_label==("${tag}")`
              }

              if (ADC_CONFIG?.filterOnCurrentSite) {
                body.q = `${body.q} AND @lumapps_site_id=(${INSTANCE_ID})`
              }

              body.enableQuerySyntax = true
              request.body = JSON.stringify(body)
            }
            return request
          },
        },
      })
      setCoveoUserContext(engine, userData)
      setEngine(engine)
    })
  }
  getSharedCoveoToken({
    lumappsAccessToken,
    tokenServiceBaseUrl,
    setAccessToken: onTokenAvailable,
    usePost,
    queryParams,
  })
}

const getSharedCoveoEngine = ({
  caller,
  lumappsAccessToken,
  tokenServiceBaseUrl,
  engine,
  setEngine,
  usePost = false,
  queryParams,
}: {
  caller: string
  lumappsAccessToken: string
  tokenServiceBaseUrl: string
  engine?: SearchEngine
  setEngine: (engine: SearchEngine) => void
  usePost: boolean
  queryParams?: string
}) => {
  const searchEngineWrapped = engine as SearchEngineWrapper
  if (
    window.ADC_HEADLESS &&
    window.ADC_HEADLESS.CoveoEngine &&
    searchEngineWrapped?.uid !== window.ADC_HEADLESS.CoveoEngine.uid &&
    window.ADC_HEADLESS.CoveoEngine.caller === caller
  ) {
    setEngine(window.ADC_HEADLESS.CoveoEngine)
    return
  }
  const onEngineReady = (newEngine: SearchEngine) => {
    const wrapper = newEngine as any
    wrapper.uid = new Date().getMilliseconds()
    wrapper.caller = caller
    if (!window.ADC_HEADLESS) {
      window.ADC_HEADLESS = {}
    }
    window.ADC_HEADLESS.CoveoEngine = wrapper
    setEngine(newEngine)
  }
  if (!window.ADC_HEADLESS || !window.ADC_HEADLESS.CoveoEngine)
    getCoveoEngine({
      lumappsAccessToken,
      tokenServiceBaseUrl,
      setEngine: onEngineReady,
      usePost,
      queryParams,
    })
}

const registerAdditionalFields = (headlessEngine?: SearchEngine, fields?: string[]) => {
  if (!headlessEngine) return null
  if (!fields) return headlessEngine
  const fieldActions = loadFieldActions(headlessEngine)
  headlessEngine.dispatch(fieldActions.registerFieldsToInclude(fields))
  return headlessEngine
}

const useResultList = (
  engine: SearchEngine | null,
  callback?: (state: ResultListState) => void
) => {
  if (engine) {
    const resultList = buildResultList(engine)
    if (callback) {
      const callbackWrapper = () => {
        callback(resultList.state)
      }
      resultList.subscribe(callbackWrapper)
    } // else it will be up to the developer to subscribe
    return resultList
  }
  return null
}

const useFoldedResultList = (
  engine: SearchEngine | null,
  callback?: (state: FoldedResultListState) => void
) => {
  if (engine) {
    const resultList = buildFoldedResultList(engine)
    if (callback) {
      const callbackWrapper = () => {
        callback(resultList.state)
      }
      resultList.subscribe(callbackWrapper)
    } // else it will be up to the developer to subscribe
    return resultList
  }
  return null
}

const innerWithLumAppsToken = (handler: (token: string) => void) => {
  if (window?.lumapps?.refreshToken) {
    window.lumapps.refreshToken().then((response: any) => {
      const token = response.data.token
      handler(token)
    })
    return true
  }
  return false
}

const withLumAppsToken = (handler: (token: string) => void, retries = 5) => {
  if ((retries ?? 0) > 10) return

  const success = innerWithLumAppsToken(handler)

  if (!success)
    setTimeout(() => {
      withLumAppsToken(handler, (retries ?? 0) + 1)
    }, 300)
}

const setupEngine = () => {
  return new Promise<SearchEngine>((resolve, reject) => {
    try {
      withLumAppsToken((lumappsAccessToken) => {
        getSharedCoveoEngine({
          caller: SERVICE_CALLER,
          lumappsAccessToken,
          tokenServiceBaseUrl: TOKEN_SERVICE_BASE_URL,
          setEngine: (newEngine: SearchEngine) => {
            registerAdditionalFields(newEngine, LUMAPPS_FIELDS)
            resolve(newEngine)
          },
          usePost: true,
          queryParams: `intuit_apikey=${API_KEY}`,
        })
      })
    } catch (ex) {
      reject(ex)
    }
  })
}

export {
  useResultList,
  useFoldedResultList,
  registerAdditionalFields,
  getSharedCoveoEngine,
  withLumAppsToken,
  setupEngine,
}
