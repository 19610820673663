import React, { ChangeEvent, KeyboardEvent } from 'react'
import {
  ComboBox,
  Key,
  ListBox,
  ListBoxItem,
  Popover,
  ComboBoxStateContext,
} from 'react-aria-components'
import { Suggestion } from '@coveo/headless'

import { FieldGroup, Input } from '../Field'
import { Button } from '../Button'
import { ReactComponent as SearchIcon } from '../../icons/svg/search.svg'
import { composeTailwindRenderProps } from '../utils'

export interface SearchFieldProps {
  value?: string
  placeholder?: string
  className?: string
  suggestions: Suggestion[]
  onSearch: (query: string) => void
  onChange: (query: string) => void
}

export function SearchField({
  placeholder = 'Search...',
  onSearch,
  value = '',
  className = '',
  suggestions,
  onChange,
}: SearchFieldProps) {
  const state = React.useContext(ComboBoxStateContext)

  const tryToSubmit = (ctx: any) => (event: KeyboardEvent<HTMLInputElement>) => {
    if ((event.code === 'Enter' || event.code === 'NumpadEnter') && !ctx) {
      onSearch(value)
    }
  }
  const handleOnChange = (evt: ChangeEvent<HTMLInputElement>) => {
    onChange(evt.target.value)
  }
  const triggerSearch = () => {
    onSearch(value)
  }

  const onSelectionChange = (k: Key) => {
    if (k === 'current--value') {
      return onSearch(value)
    }
    if (k) {
      return onSearch(k as string)
    }
  }

  return (
    <ComboBox
      menuTrigger="input"
      defaultFilter={() => true}
      aria-label="Search"
      allowsCustomValue
      onSelectionChange={onSelectionChange}
      onKeyUp={tryToSubmit(state?.selectedItem)}
    >
      <FieldGroup
        className={composeTailwindRenderProps(
          className,
          'flex flex-row gap-1 min-w-[40px] max-w-xl rounded-full py-4'
        )}
      >
        <Input
          placeholder={placeholder}
          className="[&::-webkit-search-cancel-button]:hidden border-none !pl-4 rounded-full rounded-r-none outline-none"
          onChange={handleOnChange}
          value={value}
        />

        <Button
          variant="icon"
          type="submit"
          className="p-5"
          aria-label="search"
          onClick={triggerSearch}
        >
          <SearchIcon
            aria-hidden
            className="w-4 h-4 text-gray-500 forced-colors:text-[ButtonText] group-disabled:text-gray-200 forced-colors:group-disabled:text-[GrayText]"
          />
        </Button>
      </FieldGroup>

      <Popover className="max-h-60 w-[--trigger-width] overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black/5 entering:animate-in entering:fade-in exiting:animate-out exiting:fade-out">
        <ListBox>
          {/* {value && (
            <ListBoxItem
              id="current--value"
              className="group flex items-center gap-2 cursor-default select-none py-2 pl-2 pr-4 outline-none rounded text-gray-900 focus:bg-sky-600 focus:text-white"
            >
              Search for "{value}"
            </ListBoxItem>
          )} */}
          {suggestions.map((item) => (
            <ListBoxItem
              key={item.rawValue}
              id={item.rawValue}
              className="group flex items-center gap-2 cursor-default select-none py-2 pl-2 pr-4 outline-none rounded text-gray-900 focus:bg-sky-600 focus:text-white"
            >
              <SearchIcon className="text-gray-400 w-4 h-4 focus:text-gray-50" /> {item.rawValue}
            </ListBoxItem>
          ))}
        </ListBox>
      </Popover>
    </ComboBox>
  )
}
