import React from 'react'
import { QuerySummaryState } from '@coveo/headless'

export const useIsFullLoading = (querySummary: QuerySummaryState) => {
  const [lastQuery, setLastQuery] = React.useState('')

  return React.useMemo(() => {
    if (querySummary.isLoading && querySummary.query !== lastQuery) {
      setLastQuery(querySummary.query)
      return true
    }
    return false
  }, [querySummary.isLoading, querySummary.query])
}
