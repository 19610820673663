import { getFirstGroup } from './regexp'

export const isTagzQuery = (query: string): boolean => {
  return query.startsWith('tag:')
}

export const getTagzFromQuery = (query: string): string => {
  const regexp = /^tag:"(.*)"$/g
  const res = getFirstGroup(regexp, query)

  return res ? res[0] : ''
}

export const createTagzQuery = (tagz: string): string => {
  return `tag:"${tagz}"`
}
