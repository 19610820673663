import { twMerge } from 'tailwind-merge'
import Divider from '../Results/Divider'

export type LoadingSideFacetsProps = {
  className?: string
}
export function LoadingFacetValue() {
  return (
    <div className="mt-3 flex items-center justify-start gap-3">
      <div className="h-6 bg-skeleton rounded-none w-6"></div>
      <div className="h-3 bg-skeleton rounded-full w-[60%]"></div>
    </div>
  )
}
export default function LoadingSideFacets({ className = '' }: LoadingSideFacetsProps) {
  return (
    <div role="status" className={twMerge('max-w-full animate-pulse', className)}>
      <div className="h-6 bg-skeleton rounded-full max-w-20 mb-3"></div>
      <Divider />
      <div className="mt-4 h-4 bg-skeleton rounded-full max-w-20 mb-6"></div>
      <LoadingFacetValue />
      <LoadingFacetValue />
      <LoadingFacetValue />
      <span className="sr-only">Loading...</span>
    </div>
  )
}
