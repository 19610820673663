export const addQueryParam = (key: string, value: string) => {
  const url = new URL(window.location.href)
  url.searchParams.set(key, value)
  window.history.pushState(null, '', url.toString())
}

export function removeQueryParam(paramKey: string) {
  const r = new URL(window.location.href)
  r.searchParams.delete(paramKey)
  const newUrl = r.href
  window.history.pushState({ path: newUrl }, '', newUrl)
}

export const getQueryParam = (key: string) => {
  const url = new URL(window.location.href)
  return url.searchParams.get(key) || ''
}
