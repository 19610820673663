import React from 'react'
import {
  Checkbox as AriaCheckbox,
  CheckboxGroup as AriaCheckboxGroup,
  CheckboxGroupProps as AriaCheckboxGroupProps,
  CheckboxProps as AriaCheckboxProps,
  ValidationResult,
} from 'react-aria-components'
import { tv } from 'tailwind-variants'

import { Description, FieldError, Label } from './Field'
import { composeTailwindRenderProps, focusRing } from './utils'
import { ReactComponent as Check } from '../icons/svg/checkmark.svg'
import { ReactComponent as Minus } from '../icons/svg/checkmark-partial.svg'
import { twMerge } from 'tailwind-merge'

export interface CheckboxGroupProps extends Omit<AriaCheckboxGroupProps, 'children'> {
  label?: string
  children?: React.ReactNode
  description?: string
  errorMessage?: string | ((validation: ValidationResult) => string)
}

export function CheckboxGroup(props: CheckboxGroupProps) {
  return (
    <AriaCheckboxGroup
      {...props}
      className={composeTailwindRenderProps(props.className, 'flex flex-col gap-2')}
    >
      <Label>{props.label}</Label>
      {props.children}
      {props.description && <Description>{props.description}</Description>}
      <FieldError>{props.errorMessage}</FieldError>
    </AriaCheckboxGroup>
  )
}

const checkboxStyles = tv({
  base: 'flex gap-2 items-center group text-sm transition cursor-pointer w-fit',
  variants: {
    isDisabled: {
      false: 'text-gray-800',
      true: 'text-gray-300 forced-colors:text-[GrayText]',
    },
  },
})

const boxStyles = tv({
  extend: focusRing,
  base: 'w-fit w-5 h-5 flex-shrink-0 rounded flex items-center justify-center border-2 transition',
  variants: {
    isSelected: {
      false:
        'bg-white border-[--color] [--color:theme(colors.gray.400)] group-pressed:[--color:theme(colors.gray.500)]',
      true: 'bg-primary-500 border-[--color] [--color:theme(colors.gray.700)] group-pressed:[--color:theme(colors.gray.800)] forced-colors:![--color:Highlight]',
    },
    isInvalid: {
      true: '[--color:theme(colors.red.700)] forced-colors:![--color:Mark] group-pressed:[--color:theme(colors.red.800)]',
    },
    isDisabled: {
      true: '[--color:theme(colors.gray.200)] forced-colors:![--color:GrayText]',
    },
  },
})

const iconStyles =
  'w-4 h-4 text-white group-disabled:text-gray-400 forced-colors:text-[HighlightText]'

export type CheckboxProps = Omit<AriaCheckboxProps, 'className'> & {
  className?: string
}
export function Checkbox({ className = '', ...props }: CheckboxProps) {
  return (
    <AriaCheckbox
      {...props}
      className={twMerge(
        checkboxStyles({ isSelected: props.isSelected || props.isIndeterminate, ...props }),
        className
      )}
    >
      {({ isSelected, isIndeterminate, ...renderProps }) => (
        <>
          <div className={boxStyles({ isSelected: isSelected || isIndeterminate, ...renderProps })}>
            {isIndeterminate ? (
              <Minus aria-hidden className={iconStyles} />
            ) : isSelected ? (
              <Check aria-hidden className={iconStyles} />
            ) : null}
          </div>
          {props.children}
        </>
      )}
    </AriaCheckbox>
  )
}
