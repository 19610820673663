import React from 'react'

import { TFacet } from '../../types'
import { Button } from '../Button'
import Facet from './Facet'
import { HIDDEN_FACETS } from '../../constants'

export interface SideFacetsProps {
  facets: TFacet[]
}
// TODO: facet search
export default function SideFacets({ facets }: SideFacetsProps) {
  const hasFacetsSelected = React.useMemo(() => {
    return facets.findIndex((facet) => facet.state.hasActiveValues) >= 0
  }, [facets])

  const clearAll = () => {
    facets.forEach((facet) => facet.deselectAll())
  }

  const facetsWithValues = React.useMemo(() => {
    return facets.filter(
      (facet) => facet.state.values.length > 0 && !HIDDEN_FACETS.includes(facet.state.facetId)
    )
  }, [facets])

  return (
    <>
      <h3 className="font-bold flex align-middle justify-between">
        <span>Filter</span>
        {hasFacetsSelected && (
          <Button variant="link" onClick={clearAll}>
            Clear
          </Button>
        )}
      </h3>
      <hr className="border-b-1 border-b-gray-200 my-3 mb-5" />
      <div className="flex flex-col gap-5">
        {facetsWithValues.map((facet, index) => (
          <Facet
            key={facet.state.facetId}
            facet={facet}
            isDefaultOpen={index < 3 || facet.state.hasActiveValues}
            className="mb-4"
          />
        ))}
      </div>
    </>
  )
}
