import React from 'react'
import { ReactComponent as FileSearchIcon } from '../../icons/svg/file-search.svg'

export type SearchPagePanelLayoutProps = {
  sideElement: React.ReactNode
  titleElement: React.ReactNode
  sortElement?: React.ReactNode
  children: React.ReactNode
  tabId: string
}

export default function SearchPagePanelLayout({
  sideElement,
  titleElement,
  sortElement,
  children,
  tabId,
}: SearchPagePanelLayoutProps) {
  return (
    <div data-id={tabId} className="flex gap-9">
      <div className="w-64">{sideElement}</div>
      <section className="flex-1 max-w-full">
        <div className="flex flex-row justify-between items-center">
          <p className="text-sm font-normal inline-flex items-center gap-1">
            <FileSearchIcon width={20} height={20} className="mr-1 font-bold text-black" />
            {titleElement}
          </p>
          {sortElement}
        </div>
        {children}
      </section>
    </div>
  )
}
