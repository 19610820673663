import { twMerge } from 'tailwind-merge'
import { tv } from 'tailwind-variants'
export type SkeletonProps = {
  size?: 'sm' | 'md' | 'lg'
  className?: string
}

const skeleton = tv({
  base: 'w-60 animate-pulse rounded bg-gray-300',
  variants: {
    size: {
      sm: 'h-3',
      md: 'h-5',
      lg: 'h-10',
    },
  },
})
export function Skeleton({ className, size = 'md' }: SkeletonProps) {
  return <div className={twMerge(skeleton({ size }), className)}></div>
}

const iconSkeleton = tv({
  base: 'animate-pulse rounded rounded-full bg-gray-300',
  variants: {
    size: {
      sm: 'h-5 w-5',
      md: 'h-10 w-10',
      lg: 'h-20 w-20',
    },
  },
})
export function IconSkeleton({ className, size = 'md' }: SkeletonProps) {
  return <div className={twMerge(iconSkeleton({ size }), className)}></div>
}
