import React from 'react'

import { TBreadcrumb, TLumAppsRaw, TResult } from '../types'
import { LUMAPPS_TYPES } from '../constants'

export const useLumappsBreadcrumb = (raw: TResult['raw'], clickUri: string) => {
  const lumraw = raw as unknown as TLumAppsRaw

  return React.useMemo(() => {
    switch (lumraw.lumapps_type) {
      case LUMAPPS_TYPES.content:
        return buildContentBreadcrumb(lumraw, clickUri)
      case LUMAPPS_TYPES.post:
        return buildPostBreadcrumb(lumraw, clickUri)
      default:
        return []
    }
  }, [lumraw])
}

const buildSiteItem = (raw: TLumAppsRaw, clickUri: string): TBreadcrumb => {
  const siteSlug = raw.lumapps_site_slug as string
  const siteUrl = `${clickUri.split(siteSlug)[0]}${siteSlug}`

  return {
    label: raw.lumapps_site_name,
    url: siteUrl,
  }
}

const buildContentBreadcrumb = (raw: TLumAppsRaw, clickUri: string): TBreadcrumb[] => {
  return [{ label: raw.lumapps_custom_content_type_name }, buildSiteItem(raw, clickUri)]
}

const buildPostBreadcrumb = (raw: TLumAppsRaw, clickUri: string): TBreadcrumb[] => {
  const li = [
    { label: 'Post' },
    buildSiteItem(raw, clickUri),
    { label: raw.lumapps_community_title, url: raw.lumapps_community_url },
  ]
  if (raw.lumapps_tags_name) li.push({ label: raw.lumapps_tags_name?.join(', ') })
  return li
}
