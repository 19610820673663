import { format } from 'date-fns'

import { type TRaw, type TResult } from '../../types'
import Tag from './Tag'

export type ResultMetadataProps = {
  result: TResult
}
export default function ResultMetadata({ result }: ResultMetadataProps) {
  const { intuitorigin, intuitvpn, gdintuitacl, lumapps_comments, lumapps_likes } =
    result.raw as TRaw
  const formattedDate = result.raw.date ? format(new Date(result.raw.date || ''), 'PPP') : ''

  return (
    <p className="text-ternary text-sm font-light">
      {intuitorigin ? `${intuitorigin[0]} - Last modified on ${formattedDate}` : formattedDate}
      {lumapps_likes >= 0 ? <span className="mx-2">- {lumapps_likes} likes</span> : ''}
      {lumapps_comments >= 0 ? <span className="my-2">- {lumapps_comments} comments</span> : ''}
      {intuitvpn || gdintuitacl ? <Tag>{intuitvpn ? 'VPN' : gdintuitacl}</Tag> : null}
    </p>
  )
}
