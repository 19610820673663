import { twMerge } from 'tailwind-merge'

export type LoadingResultProps = {
  className?: string
}
export default function LoadingResult({ className = '' }: LoadingResultProps) {
  return (
    <div role="status" className={twMerge('max-w-full animate-pulse', className)}>
      <div className="h-4 bg-skeleton rounded-full max-w-xl mb-4"></div>
      <div className="h-2 bg-skeleton rounded-full max-w-xl mb-2.5"></div>
      <div className="h-2 bg-skeleton rounded-full max-w-[360px] mb-2.5"></div>

      <div className="h-2 mt-6 bg-skeleton rounded-full max-w-[330px] mb-2.5"></div>
      <div className="h-2 bg-skeleton rounded-full max-w-[300px] mb-2.5"></div>
      <span className="sr-only">Loading...</span>
    </div>
  )
}
