import React from 'react'
import { buildInteractiveResult, HighlightUtils, SearchEngine } from '@coveo/headless'

import { TResult } from '../../types'
import ResultMetadata from './ResultMetadata'
import { Title } from './Title'
import { Breadcrumb } from './Breadcrumb'
import { LUMAPPS_TYPES } from '../../constants'
import ResultAuthor from './ResultAuthor'
import ResultTagz from './ResultTagz'

export interface ResultProps {
  result: TResult
  engine: SearchEngine
}

export default function Result({ result, engine }: ResultProps) {
  const shouldDisplayBreadcrumb = React.useMemo(() => {
    return Object.keys(LUMAPPS_TYPES).includes(result.raw.lumapps_type as string)
  }, [result])

  const highlightExcerpt = (result: TResult) => {
    return HighlightUtils.highlightString({
      content: result.excerpt,
      highlights: result.excerptHighlights,
      openingDelimiter: '<strong>',
      closingDelimiter: '</strong>',
    })
  }

  const interactiveResult = buildInteractiveResult(engine, { options: { result } })
  const onClick = () => {
    interactiveResult.select()
  }

  return (
    <div className="flex flex-col">
      <Title clickUri={result.clickUri} title={result.title} onClick={onClick} />
      {shouldDisplayBreadcrumb ? <Breadcrumb clickUri={result.clickUri} raw={result.raw} /> : null}
      <div
        className="mt-2 font-normal"
        dangerouslySetInnerHTML={{ __html: highlightExcerpt(result) }}
      />
      <ResultTagz result={result} className="mt-2" />
      <a
        href={result.clickUri}
        onClick={onClick}
        className="text-gray-600 text-xs font-thin mt-3 hover:underline"
      >
        {result.clickUri}
      </a>
      <div className="flex flex-row gap-2 mt-2 items-center">
        <ResultAuthor result={result} />
        <ResultMetadata result={result} />
      </div>
    </div>
  )
}
