import { TRaw } from '../types'

export const getUserFullname = (raw: TRaw) => {
  const { lumapps_author_firstname, lumapps_author_lastname } = raw
  if (!lumapps_author_firstname && !lumapps_author_lastname) {
    return 'Unknown'
  }

  return `${lumapps_author_firstname} ${lumapps_author_lastname}`
}
