import {
  buildSearchParameterManager,
  buildSearchStatus,
  SearchEngine,
  SearchParameters,
} from '@coveo/headless'
import React from 'react'
import { getSearchQueryFromUrl } from '../../utils/getSearchQueryFromUrl'

export function useUrlManager(engine: SearchEngine) {
  return React.useEffect(() => {
    const searchStatus = buildSearchStatus(engine)
    const getUrl = () => window.location
    const url = getUrl()
    const parameters = deserialize(url)
    const manager = buildSearchParameterManager(engine, {
      initialState: { parameters },
    })

    engine.executeFirstSearch()

    const unsubscribeManager = manager.subscribe(() => {
      const url = serialize(manager.state.parameters)
      if (!searchStatus.state.firstSearchExecuted) {
        history.replaceState(null, document.title, url)

        return
      }
      history.pushState(null, document.title, url)
    })

    const onHashChange = () => {
      const url = getUrl()
      const parameters = deserialize(url)
      manager.synchronize(parameters)
    }

    function serialize({ q, f = {}, sortCriteria }: SearchParameters) {
      const filters: string[] = []
      Object.keys(f).forEach((key) => {
        const value = f[key]
        if (Array.isArray(value)) {
          // TODO: handle multiple values
          filters.push(`f-${key}=${encodeURI(JSON.stringify(value))}`)

          // value.forEach((v) => {
          //   filters.push(`${key}=${v}`)
          // })
        } else {
          filters.push(`f-${key}=${value}`)
        }
      })

      const sortParam = sortCriteria ? `s=${encodeURIComponent(sortCriteria)}` : ''

      if (q) {
        return `?q=${q}#${sortCriteria && `${sortParam}&`}${filters.length ? `${filters.join('&')}` : ''}`
        // return `?q=${q}#s=@date ascending&f-lumapps_type=post`
      }

      return ''
    }

    function deserialize(url: Location) {
      // TODO: handle when tag is click = url with no search but /search/query
      const query = getSearchQueryFromUrl()
      const parts = url.hash.slice(1).split('&')
      const filters: Record<string, string[]> = {}
      let sort = ''

      // Apply dynamic filters
      for (const part of parts) {
        const [key, value] = part.split('=')
        if (value) {
          if (key.startsWith('f-')) {
            const decodedValue = JSON.parse(decodeURI(value))
            filters[key.replace('f-', '')] = decodedValue
          } else if (key === 's') {
            sort = decodeURIComponent(value)
          }
        }
      }

      const parameters: SearchParameters = {
        q: query,
        f: filters,
        sortCriteria: sort,
      }
      return parameters
    }

    window.addEventListener('hashchange', onHashChange)
    return () => {
      window.removeEventListener('hashchange', onHashChange)
      unsubscribeManager()
    }
  }, [engine])
}
