import { Raw } from '@coveo/headless'
import { tv } from 'tailwind-variants'

import { ReactComponent as ChevronIcon } from '../../icons/svg/chevron-sm-right.svg'
import { useLumappsBreadcrumb } from '../../hooks/useLumappsBreadcrumb'
import { TBreadcrumb } from '../../types'

const itemClasses = tv({
  base: '',
  variants: {
    isLink: {
      true: 'cursor-pointer hover:text-secondary-700 hover:underline text-secondary-500',
      false: '',
    },
  },
})
export type BreadcrumbProps = { raw: Raw; clickUri: string }

export const BreadcrumbItem = ({ item, isLast }: { item: TBreadcrumb; isLast: boolean }) => {
  return (
    <li
      key={item.label}
      className="flex items-center text-gray-500 font-sans text-sm font-normal leading-normal antialiased transition-colors duration-300"
    >
      <a href={item.url} className={itemClasses({ isLink: Boolean(item.url) })}>
        <span>{item.label}</span>
      </a>
      {!isLast ? (
        <ChevronIcon
          width={16}
          height={16}
          className="line-clamp-1 pointer-events-none select-none font-sans text-sm font-normal leading-normal text-blue-gray-500 antialiased"
        />
      ) : null}
    </li>
  )
}
export const Breadcrumb = ({ raw, clickUri }: BreadcrumbProps) => {
  const items = useLumappsBreadcrumb(raw, clickUri)

  if (items.length === 0) {
    return null
  }

  return (
    <nav aria-label="breadcrumb">
      <ol className="flex w-full flex-wrap items-center rounded-md">
        {items.map((item, index) => (
          <BreadcrumbItem key={item.label} item={item} isLast={index === items.length - 1} />
        ))}
      </ol>
    </nav>
  )
}
