import Divider from '../Results/Divider'
import LoadingResult from '../Results/LoadingResult'
import LoadingSideFacets from '../SideFacets/LoadingSideFacets'
import { Skeleton } from '../Skeleton'
import SearchPagePanelLayout from './SearchPagePanelLayout'

export default function SearchPageLoading() {
  return (
    <SearchPagePanelLayout
      tabId="loading"
      titleElement={<Skeleton size="sm" className="w-20" />}
      sideElement={<LoadingSideFacets />}
    >
      <>
        <LoadingResult className="mt-6 mb-6" />
        <Divider />
        <LoadingResult className="mt-6 mb-6" />
        <Divider />
        <LoadingResult className="mt-6 mb-6" />
      </>
    </SearchPagePanelLayout>
  )
}
