/* eslint-disable no-undef */
import { KNOWN_FACETS, TADCConfig } from './types'

declare global {
  interface Window {
    CUSTOMER_ID: string
    CUSTOMER_SLUG: string
    INSTANCE_SLUG: string
    INSTANCE_ID: string
    INSTANCE: Record<string, string>
    INIT_URL?: string
    ADC_CONFIG?: TADCConfig
  }
}
export const CUSTOMER_ID = window.CUSTOMER_ID
export const CUSTOMER_SLUG = window.CUSTOMER_SLUG
export const INSTANCE_SLUG = window.INSTANCE_SLUG || ''
export const INSTANCE_ID = window.INSTANCE_ID || window.INSTANCE?.id || ''

export const ENV = process.env.NODE_ENV
export const MODE = process.env.REACT_APP_MODE
export const SESSION = process.env.REACT_APP_SESSION

export const ADC_CONFIG = window.ADC_CONFIG as TADCConfig

export const IS_DEV = ENV === 'development'
export const IS_ADC = process.env.IS_ADC || IS_DEV || ADC_CONFIG?.tokenServiceBaseType === 'adc'

export const TOKEN_SERVICE_BASE_URL = `${IS_DEV ? 'http://127.0.0.1:8000/' : ''}${IS_ADC ? 'https://lumappstokenexchange.api.intuit.com' : 'https://lumappstokenexchange.api.intuit.com'}`

export const SERVICE_CALLER = 'adc-headless-search-demo'
export const API_KEY = 'prdakyresRTlQZreAlbn9xSbEMYNXJXQGsEfNTp0'

// Get proper facet name
export const FACETS_NAME: Record<KNOWN_FACETS, string> = {
  intuitorigin: 'Source',
  // capabilities: 'Capabilities',
  lumapps_author_fullname: 'Author',
  lumapps_community_title: 'Community',
  lumapps_post_type: 'Post type',
  lumapps_content_type: 'Content type',
  lumapps_type: 'Document type',
  date: 'Last update',
  lumapps_site_name: 'Site',
  lumapps_searchable_metadata: 'Metadata',
  lumapps_community_privacy: 'Community types',
}

export const HIDDEN_FACETS: string[] = []

export const PAGE_SIZE = 10

export const INTUIT_FIELDS = [
  'source',
  'date',
  'intuitvpn',
  'gdintuitacl',
  'architects',
  'goaltype',
  'intuitorigin',
  'slack',
  'supporturl',
  'owner',
]

export const LUMAPPS_FIELDS = [
  ...INTUIT_FIELDS,
  'lumapps_apiprofile_email',
  'lumapps_apiprofile_organizations_department',
  'lumapps_apiprofile_organizations_location',
  'lumapps_apiprofile_organizations_name',
  'lumapps_apiprofile_organizations_title',
  'lumapps_apiprofile_phones_number',
  'lumapps_apiprofile_phones_type',
  'lumapps_apiprofile_raw',
  'lumapps_attachments_count',
  'lumapps_author_customer',
  'lumapps_author_customerid',
  'lumapps_author_email',
  'lumapps_author_firstname',
  'lumapps_author_fullname',
  'lumapps_author_id',
  'lumapps_author_lastname',
  'lumapps_author_url',
  'lumapps_comments',
  'lumapps_community_id',
  'lumapps_community_members_raw',
  'lumapps_community_member_count',
  'lumapps_community_member_count_text',
  'lumapps_community_privacy',
  'lumapps_community_slug',
  'lumapps_community_title',
  'lumapps_community_url',
  'lumapps_content_properties_layout',
  'lumapps_content_properties_name',
  'lumapps_content_properties_order',
  'lumapps_content_type',
  'lumapps_created_at',
  'lumapps_custom_content_type_icon',
  'lumapps_custom_content_type_id',
  'lumapps_custom_content_type_name',
  'lumapps_directory_entry_favorites_count',
  'lumapps_directory_entry_field_id',
  'lumapps_directory_entry_field_name',
  'lumapps_email',
  'lumapps_external_metadata',
  'lumapps_first_name',
  'lumapps_full_name',
  'lumapps_id',
  'lumapps_lang',
  'lumapps_last_name',
  'lumapps_likes',
  'lumapps_media_height',
  'lumapps_media_mime_type',
  'lumapps_media_name',
  'lumapps_media_size',
  'lumapps_media_type',
  'lumapps_media_width',
  'lumapps_post_type',
  'lumapps_publication_date',
  'lumapps_relevant_comment_id',
  'lumapps_scores',
  'lumapps_searchable_metadata',
  'lumapps_searchable_metadata_raw',
  'lumapps_site_id',
  'lumapps_site_name',
  'lumapps_site_parent_id',
  'lumapps_site_slug',
  'lumapps_slug',
  'lumapps_status',
  'lumapps_tags_id',
  'lumapps_tags_name',
  'lumapps_tags_raw',
  'lumapps_tagz_label',
  'lumapps_tagz_raw',
  'lumapps_thumbnail',
  'lumapps_type',
  'lumapps_updated_at',
]

export const QUERY_PARAMS_KEYS = {
  page: 'p',
  query: 'q',
  facets: 'f',
} as const

export const LUMAPPS_TYPES = {
  community: 'community',
  content: 'content',
  directoryentry: 'directoryentry',
  post: 'post',
  media: 'media',
} as const
