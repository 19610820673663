import { useEffect, useState } from 'react'
import { Controller } from '@coveo/headless'

export const useBuilder = <T, C extends Controller>(controller: C) => {
  const [state, setState] = useState<T>(controller.state as T)

  useEffect(() => {
    controller.subscribe(() => setState(controller.state as T))
  }, [])

  return { state, controller }
}
