import React from 'react'
import { tv } from 'tailwind-variants'

const tagStyle = tv({
  base: 'ml-2 bg-ternary text-ternary-contrast text-xs font-medium me-1 px-1 py-0.5 rounded',
  variants: {
    isClickable: {
      true: '',
      false: '',
    },
  },
})
export type TagProps = {
  children: string | React.ReactNode
  onClick?: () => void
}
export default function Tag({ children, onClick }: TagProps) {
  return <span className={tagStyle({ isClickable: Boolean(onClick) })}>{children}</span>
}
