import { Facet, SearchEngine, buildFacet } from '@coveo/headless'
import { KNOWN_FACETS_ORDER } from '../../types'
import { HIDDEN_FACETS } from '../../constants'

export const buildFacets = (engine: SearchEngine): Facet[] => {
  const list = window.ADC_CONFIG?.facets?.length ? window.ADC_CONFIG?.facets : KNOWN_FACETS_ORDER
  const newList = [...list, ...HIDDEN_FACETS]
  return newList.map((facet) => {
    return buildFacet(engine, {
      options: { field: facet, facetId: facet },
    })
  })
}

export const buildUserFacets = (engine: SearchEngine) => {
  const sourceFacetController = buildFacet(engine, {
    options: { field: 'intuitorigin', facetId: 'intuitorigin' },
  })
  const postTypeFacetController = buildFacet(engine, {
    options: { field: 'capabilities', facetId: 'capabilities' },
  })

  return [sourceFacetController, postTypeFacetController]
}
