import React from 'react'
import { OverlayArrow, Tooltip, TooltipTrigger } from 'react-aria-components'
import Avatar from './Avatar'

import { createUserProfileUrl } from '../utils/createUserProfileUrl'
import { Button } from './Button'

export type UserCardProps = {
  id: string
  fullName: string
  email?: string
}
export default function UserCardTrigger({ id, fullName, email }: UserCardProps) {
  const profileUrl = createUserProfileUrl(id)
  const [isOpen, setOpen] = React.useState(false)

  return (
    <TooltipTrigger delay={0} isOpen={isOpen} onOpenChange={setOpen}>
      <Button variant="link" className="flex items-center gap-2">
        <Avatar boxSize="24" userId={id} className="w-[24px] h-[24px]" />

        <a
          href={profileUrl}
          target="_blank"
          aria-label="See details"
          className="text-sm font-medium text-black hover:underline"
        >
          {fullName}
        </a>
      </Button>
      <Tooltip className="shadow-md rounded-md bg-white">
        <OverlayArrow />
        <div className="p-4 min-w-56 flex flex-col items-center shadow-md">
          <Avatar boxSize="64" userId={id} className="w-[64px] h-[64px]" />
          <p className="font-medium mt-2">{fullName}</p>
          {email && <p className="font-sm text-sm text-gray-400">{email}</p>}
          <a
            href={profileUrl}
            target="_blank"
            className="text-primary-500 text-xs mt-5 hover:border-primary-500 border border-transparent p-1 rounded-md hover:bg-gray-100 transition-all"
          >
            More information
          </a>
        </div>
      </Tooltip>
    </TooltipTrigger>
  )
}
