import {
  Disclosure,
  DisclosureContent,
  DisclosureProvider,
  useDisclosureStore,
} from '@ariakit/react'
import { FacetValue } from '@coveo/headless'
import { twMerge } from 'tailwind-merge'

import { KNOWN_FACETS, TFacet } from '../../types'
import { FACETS_NAME } from '../../constants'
import { Checkbox } from '../Checkbox'
import { ReactComponent as ChevronUp } from '../../icons/svg/chevron-up.svg'
import { ReactComponent as ChevronDown } from '../../icons/svg/chevron-down.svg'

export interface FacetProps {
  facet: TFacet
  isDefaultOpen?: boolean
  className?: string
}
const capitalize = (str: string) => {
  if (!str) return str
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

const facetValue = (value: string) => {
  return value.toUpperCase() == value || value.toLocaleLowerCase() == value
    ? capitalize(value.replace(/_/g, ' '))
    : value
}

export default function Facet({ facet, className, isDefaultOpen = true }: FacetProps) {
  const store = useDisclosureStore({ defaultOpen: isDefaultOpen })
  const state = store.useState()

  const onValueChange = (value: FacetValue) => () => {
    // TODO: change in the URL
    facet.toggleSelect(value)
  }

  if (facet.state.values.length === 0) {
    return null
  }

  return (
    <div key={facet.state.facetId} className={twMerge('mb-4', className)}>
      <DisclosureProvider store={store}>
        <Disclosure className="w-full">
          <h4 className="inline-flex items-center justify-between font-semibold text-sm w-full">
            <span>
              {facet.state.label ||
                FACETS_NAME[facet.state.facetId as KNOWN_FACETS] ||
                facet.state.facetId}
            </span>
            {state.open ? (
              <ChevronUp className="inline ml-2" width="20" height="20" />
            ) : (
              <ChevronDown className="inline ml-2" width="20" height="20" />
            )}
          </h4>
        </Disclosure>
        <DisclosureContent className="flex flex-col gap-3 pt-5">
          {facet.state.values.map((value) => {
            return (
              <Checkbox
                key={value.value}
                onChange={onValueChange(value)}
                isSelected={facet.isValueSelected(value)}
              >
                <p className="w-fit">
                  {facetValue(value.value)} ({value.numberOfResults})
                </p>
              </Checkbox>
            )
          })}
        </DisclosureContent>
      </DisclosureProvider>
    </div>
  )
}
