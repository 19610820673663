import WarningSection from '../WarningSection'
import { ReactComponent as ExclamationIcon } from '../../icons/svg/circle-exclamation.svg'

export default function ErrorSearchPage() {
  return (
    <WarningSection IconComponent={ExclamationIcon} title="Can't setup the engine">
      <p>Please retry later</p>
      <p>If the error persist, please contact your administrator</p>
    </WarningSection>
  )
}
