import {
  composeRenderProps,
  Button as RACButton,
  ButtonProps as RACButtonProps,
} from 'react-aria-components'
import { tv } from 'tailwind-variants'

import { focusRing } from './utils'

export interface ButtonProps extends RACButtonProps {
  variant?: 'primary' | 'secondary' | 'destructive' | 'icon' | 'link' | 'outline'
  isActive?: boolean
  onClick?: () => void
}

const button = tv({
  extend: focusRing,
  base: 'px-5 py-2 text-sm text-center transition rounded-lg border border-black/10 shadow-[inset_0_1px_0_0_rgba(255,255,255,0.1)]',
  variants: {
    variant: {
      link: 'cursor-pointer hover:bg-grey-500 pressed:bg-grey-800 text-primary-500 px-0 py-0 border-none',
      outline:
        'cursor-pointer hover:bg-grey-500 pressed:bg-grey-800 text-primary px-0 py-0 border-none',
      primary: 'bg-blue-600 hover:bg-blue-700 pressed:bg-blue-800 text-white',
      secondary: 'bg-gray-100 hover:bg-gray-200 pressed:bg-gray-300 text-gray-800',
      destructive: 'bg-red-700 hover:bg-red-800 pressed:bg-red-900 text-white',
      icon: 'cursor-pointer border-0 p-1 flex items-center justify-center text-gray-600 hover:bg-black/[5%] pressed:bg-black/10 disabled:bg-transparent',
    },
    isDisabled: {
      true: 'cursor-not-allowed bg-gray-100 forced-colors:text-[GrayText] border-black/5',
    },
    isActive: {
      true: 'bg-blue-200',
    },
  },
  defaultVariants: {
    variant: 'primary',
  },
})

export function Button(props: ButtonProps) {
  return (
    <RACButton
      {...props}
      className={composeRenderProps(props.className, (className, renderProps) =>
        button({ ...renderProps, variant: props.variant, className })
      )}
    />
  )
}
