import React from 'react'
import { SearchEngine } from '@coveo/headless'

import { SearchField } from './SearchField'
import LoadingSearchField from './LoadingSearchField'
import { useSearchBox } from '../../hooks/useSearchBox'
import { createSearchUrl } from '../../utils/createSearchUrl'
import { isSearchPage } from '../../utils/isSearchPage'
import { IS_DEV } from '../../constants'
import { getSearchQueryFromUrl } from '../../utils/getSearchQueryFromUrl'

export type ResolverSearchFieldProps = {
  enginePromise: Promise<SearchEngine>
}
export default function ResolverSearchField({ enginePromise }: ResolverSearchFieldProps) {
  const [engine, setEngine] = React.useState<SearchEngine | null>(null)
  const searchQuery = React.useMemo(() => getSearchQueryFromUrl(), [])

  React.useEffect(() => {
    enginePromise.then((newEngine) => {
      setEngine(newEngine)
    })
  }, [])

  if (!engine) {
    return <LoadingSearchField />
  }

  return <ConnectedSearchField engine={engine} initialQuery={searchQuery} />
}

export type ConnectedSearchFieldProps = {
  engine: SearchEngine
  initialQuery?: string
}
export function ConnectedSearchField({ engine, initialQuery = '' }: ConnectedSearchFieldProps) {
  const searchbox = useSearchBox(engine)
  const [query, setQuery] = React.useState(initialQuery)

  const triggerSearch = (query: string) => {
    setQuery(query)
    searchbox.controller.updateText(query)
    searchbox.controller.submit()

    // Should redirect to the search page
    if (!IS_DEV && !isSearchPage()) {
      window.location.href = createSearchUrl(query)
    }
  }

  const onQueryChange = (query: string) => {
    setQuery(query)
  }

  React.useEffect(() => {
    window.addEventListener('queryurlchange', () => {
      const query = getSearchQueryFromUrl()
      if (query) {
        triggerSearch(query)
      }
    })
  }, [])

  // TODO: use ErrorBoundary
  return (
    <SearchField
      aria-label="Search"
      value={query}
      suggestions={searchbox.state.suggestions}
      onChange={onQueryChange}
      onSearch={triggerSearch}
    />
  )
}
