import React from 'react'
import { QuerySummaryState, SearchStatusState } from '@coveo/headless'

import SideFacets from '../SideFacets'
import { TFacet } from '../../types'
import LoadingResult from '../Results/LoadingResult'
import Divider from '../Results/Divider'
import LoadingSideFacets from '../SideFacets/LoadingSideFacets'
import Sort from '../Sort'
import { TSearchPageTab } from './types'
import { Skeleton } from '../Skeleton'
import { getSearchQueryFromUrl } from '../../utils/getSearchQueryFromUrl'
import SearchPagePanelLayout from './SearchPagePanelLayout'

export type SearchPagePanelProps = {
  children: React.ReactNode
  tabId: string
  querySummary: QuerySummaryState
  facets: TFacet[]
  sort: TSearchPageTab['sort']
  status: SearchStatusState
}

export default function SearchPagePanel({
  children,
  facets,
  querySummary,
  tabId,
  sort,
  status,
}: SearchPagePanelProps) {
  const { isLoading, hasResults, total } = querySummary
  const query = getSearchQueryFromUrl()

  return (
    <SearchPagePanelLayout
      tabId={tabId}
      titleElement={
        status.isLoading || !status.firstSearchExecuted ? (
          <Skeleton size="sm" className="w-20" />
        ) : (
          <>
            <span className="font-bold">{total}</span> results for{' '}
            <span className="font-bold">"{query}"</span>
          </>
        )
      }
      sideElement={
        <>
          {(status.isLoading || !status.firstSearchExecuted) && !hasResults ? (
            <LoadingSideFacets />
          ) : null}
          {hasResults && status.firstSearchExecuted ? <SideFacets facets={facets} /> : null}
        </>
      }
      sortElement={hasResults ? <Sort sort={sort} /> : null}
    >
      {(isLoading || !status.firstSearchExecuted) && (
        <>
          <LoadingResult className="mt-6 mb-6" />
          <Divider />
          <LoadingResult className="mt-6 mb-6" />
          <Divider />
          <LoadingResult className="mt-6 mb-6" />
        </>
      )}
      {children}
    </SearchPagePanelLayout>
  )
}
