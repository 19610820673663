import { Raw, type Facet as CoveoFacet, type Result } from '@coveo/headless'

export const KNOWN_FACETS_ORDER = [
  'lumapps_type',
  'date',
  'lumapps_site_name',
  'lumapps_searchable_metadata',
  'lumapps_author_fullname',
  'lumapps_content_type',
  'lumapps_post_type',
  'lumapps_community_title',
  'lumapps_community_privacy',
  'intuitorigin',
] as const

export type KNOWN_FACETS = (typeof KNOWN_FACETS_ORDER)[number]

export type TFacet = CoveoFacet

export type TResult = Result
export type TLumAppsRaw = {
  lumapps_site_id: string
  lumapps_site_name: string
  lumapps_site_slug: string
  lumapps_type: string
  lumapps_custom_content_type_name: string
  lumapps_community_url: string
  lumapps_community_slug: string
  lumapps_community_title: string
  lumapps_tagz_raw: string
  lumapps_tags_name?: string[]
  lumapps_likes: number
  lumapps_comments: number
  // lumapps_author_fullname: string SHOULD NOT BE USED
  lumapps_author_firstname: string
  lumapps_author_lastname: string
  lumapps_author_id: string
  lumapps_author_email: string
  lumapps_id: string
}
export type TRaw = Raw &
  TLumAppsRaw & {
    intuitorigin: string[]
    intuitvpn: string
    gdintuitacl: string
  }
export type TBreadcrumb = {
  label: string
  url?: string
}

export type TADCConfig = {
  tokenServiceBaseType?: 'adc' | 'lumapps'
  filterOnCurrentSite?: boolean
  facets?: string[]
  useVanityUrl?: boolean
}
