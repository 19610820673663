import React from 'react'
import { type SearchEngine } from '@coveo/headless'
import { ErrorBoundary } from 'react-error-boundary'

import SearchPage from './SearchPage'
import ErrorSearchPage from './ErrorSearchPage'
import SearchPageLoading from './SearchPageLoading'

export interface ResolverSearchPageProps {
  enginePromise: Promise<SearchEngine>
  // userEnginePromise: Promise<SearchEngine>
}
export default function ResolverSearchPage({ enginePromise }: ResolverSearchPageProps) {
  const [engine, setEngine] = React.useState<SearchEngine | null>(null)
  // const [userEngine, setUserEngine] = React.useState<SearchEngine | null>(null)

  React.useEffect(() => {
    enginePromise.then((newEngine) => {
      setEngine(newEngine)
    })
  }, [])

  if (!engine) {
    return <SearchPageLoading />
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorSearchPage}>
      <SearchPage engine={engine} />
    </ErrorBoundary>
  )
}
