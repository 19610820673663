import {
  type ResultListState,
  type SearchEngine,
  buildResultList,
  type QuerySummaryState,
  buildQuerySummary,
  type ResultList,
  type QuerySummary,
  buildPager,
  type Facet,
  type PagerState,
  type Pager,
  type Sort as HeadlessSort,
  SortState,
  buildSort,
  buildSearchStatus,
  SearchStatus,
  type SearchStatusState,
} from '@coveo/headless'

import { useBuilder } from '../../hooks/useBuilder'
import { TSearchPageTab } from './types'

export const useSearchPage = (
  engine: SearchEngine,
  facetBuilder: (engine: SearchEngine) => Facet[]
): TSearchPageTab => {
  const { state: resultList } = useBuilder<ResultListState, ResultList>(buildResultList(engine))
  const { state: querySummary } = useBuilder<QuerySummaryState, QuerySummary>(
    buildQuerySummary(engine)
  )
  const facets = facetBuilder(engine)
  const pager = useBuilder<PagerState, Pager>(buildPager(engine))
  const sort = useBuilder<SortState, HeadlessSort>(buildSort(engine))
  const status = useBuilder<SearchStatusState, SearchStatus>(buildSearchStatus(engine))

  return {
    resultList,
    querySummary,
    facets,
    pager,
    sort,
    status,
  }
}
