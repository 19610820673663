import { ReactComponent as BackslashIcon } from '../icons/svg/circle-backslash.svg'
import WarningSection from './WarningSection'

export type NoResultsProps = {
  query: string
}
export default function NoResults({ query }: NoResultsProps) {
  return (
    <WarningSection
      IconComponent={BackslashIcon}
      title={`No content results where found for ${query}`}
    >
      <p>Search tips</p>
      <ul className="list-disc pl-4">
        <li>Try a different keyword</li>
        <li>Remove any filters</li>
      </ul>
    </WarningSection>
  )
}
