import React from 'react'
import { twMerge } from 'tailwind-merge'

import { TRaw, TResult } from '../../types'
import { createSearchUrl } from '../../utils/createSearchUrl'
import { createTagzQuery } from '../../utils/tagz'

type Tag = {
  id: string
  label: string
  creationDate: string
}
const getTagList = (tags: string) => {
  try {
    if (tags) {
      return (JSON.parse(tags) as Tag[]) || []
    }
  } catch (ex) {
    console.error(ex)
  }

  return []
}

export type ResultTagzProps = {
  result: TResult
  className?: string
}
export default function ResultTagz({ result, className }: ResultTagzProps) {
  const { lumapps_tagz_raw } = result.raw as TRaw
  const tagList = getTagList(lumapps_tagz_raw)

  if (tagList.length === 0) {
    return null
  }

  const onClick = (tag: string) => (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    window.history.pushState({}, '', createSearchUrl(createTagzQuery(tag)))
    window.dispatchEvent(new Event('queryurlchange'))
    return false
  }

  return (
    <div className={twMerge('flex gap-2 flex-wrap', className)}>
      {tagList.map(({ id, label }) => (
        <a
          key={id}
          href={createSearchUrl(createTagzQuery(label))}
          onClick={onClick(label)}
          className="bg-lumx-dark-L5 rounded-full py-1 px-3 text-sm font-normal flex items-center hover:bg-lumx-dark-L4 text-lumx-dark-N hover:shadow-sm transition-all"
        >
          <span className="mr-2">#</span>
          <span className="text-xs text-gray-600 font-normal">{label}</span>
        </a>
      ))}
    </div>
  )
}
