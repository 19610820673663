import React from 'react'
import { TResult } from '../../types'
import Result from './Result'
import Divider from './Divider'
import { SearchEngine } from '@coveo/headless'

export interface ResultsProps {
  results: TResult[]
  engine: SearchEngine
}
export default function Results({ results, engine }: ResultsProps) {
  return (
    <div className="flex flex-col gap-5 mt-4">
      {results.map((result, index) => (
        <React.Fragment key={result.uniqueId}>
          <Result key={result.uniqueId} result={result} engine={engine} />
          {index < results.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </div>
  )
}
