export type TitleProps = {
  clickUri: string
  title: string
  onClick?: () => void
}

export const Title = ({ clickUri, title, onClick }: TitleProps) => {
  return (
    <a
      className="text-xl font-semibold hover:text-lumx-blue-D2  w-fit"
      href={clickUri}
      onClick={onClick}
    >
      {title}
    </a>
  )
}
