import React from 'react'
import { twMerge } from 'tailwind-merge'
import { Pager, PagerState } from '@coveo/headless'

import { ReactComponent as ChevronLeftIcon } from '../icons/svg/chevron-left.svg'
import { ReactComponent as ChevronRightIcon } from '../icons/svg/chevron-right.svg'
import { Button } from './Button'
import { PAGE_SIZE } from '../constants'
import { addQueryParam } from '../utils/queryParam'
import { tv } from 'tailwind-variants'

export type PaginationProps = {
  pager: { controller: Pager; state: PagerState }
  nbOfResults: number
  className?: string
}

export type Page = {
  value: number | null
  label: string
}

const pageStyle = tv({
  base: 'relative rounded-none inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-100 focus:z-20 focus:outline-offset-0',
  variants: {
    isActive: {
      true: 'bg-primary-500 hover:bg-primary-300 text-white ring-gray-500',
      false: '',
    },
    isIcon: {
      true: 'px-2',
      false: '',
    },
  },
})

export const Pagination: React.FC<PaginationProps> = ({ nbOfResults, className = '', pager }) => {
  const currentPage = pager.state.currentPage

  const onPageChange = (page: number) => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    addQueryParam('p', page.toString())
  }

  const onPageClick = (page: number) => () => {
    pager.controller.selectPage(page)
    onPageChange(page)
  }

  const onNextClick = () => {
    pager.controller.nextPage()
    onPageChange(currentPage + 1)
  }

  const onPreviousClick = () => {
    pager.controller.previousPage()
    onPageChange(currentPage - 1)
  }

  const { from, to } = React.useMemo(() => {
    return {
      from: (pager.state.currentPage - 1) * PAGE_SIZE + 1,
      to: pager.state.currentPage * PAGE_SIZE,
    }
  }, [pager.state])

  if (pager.state.currentPages.length <= 0) {
    return null
  }

  return (
    <div
      className={twMerge('hidden sm:flex sm:flex-1 sm:items-center sm:justify-between', className)}
    >
      <div>
        <p className="text-sm text-gray-700">
          Showing
          <span className="font-medium mx-1">{from}</span>
          to
          <span className="font-medium mx-1">{to}</span>
          of
          <span className="font-medium mx-1">{nbOfResults}</span>
          results
        </p>
      </div>
      <div>
        <nav className="isolate inline-flex -space-x-px rounded-md" aria-label="Pagination">
          <Button
            isDisabled={!pager.state.hasPreviousPage}
            variant="outline"
            onClick={onPreviousClick}
            className={pageStyle({ isActive: false, isIcon: true })}
          >
            <span className="sr-only">Previous</span>
            <ChevronLeftIcon className="h-5 w-5" />
          </Button>
          {/* <!-- Current: "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600", Default: "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0" --> */}

          {pager.state.currentPages.map((value) => (
            <Button
              key={value}
              isActive={value === currentPage}
              aria-current="page"
              isDisabled={value === -1}
              variant="outline"
              onClick={onPageClick(value)}
              className={pageStyle({ isActive: value === currentPage, isIcon: false })}
            >
              {value}
            </Button>
          ))}

          <Button
            isDisabled={!pager.state.hasNextPage}
            variant="outline"
            onClick={onNextClick}
            className={pageStyle({ isActive: false, isIcon: true })}
          >
            <span className="sr-only">Next</span>
            <ChevronRightIcon className="h-5 w-5" />
          </Button>
        </nav>
      </div>
    </div>
  )
}
