import React from 'react'
import ReactDOM from 'react-dom/client'

import './index.css'
// import reportWebVitals from './reportWebVitals'
import { buildFakeEngine } from './mocks/buildFakeEngine'
import { setupEngine } from './utils/coveoHeadlessWrapper'
import { MODE, IS_DEV, SESSION } from './constants'

import { ComponentAsCustomElement } from './components/ComponentAsCustomElement'
import SearchField from './components/SearchField'
import SearchPage from './components/SearchPage'

// INIT the common engine
if (IS_DEV && MODE !== 'mock') {
  window.lumapps = {
    refreshToken: () => Promise.resolve({ data: { token: SESSION } }),
  }
}
const enginePromise = MODE === 'mock' ? buildFakeEngine() : setupEngine()

class StandaloneSearchField extends ComponentAsCustomElement {
  mount() {
    const searchboxElement = document.getElementById('adc-headless-search-field')
    const root = ReactDOM.createRoot(searchboxElement as HTMLElement)
    root.render(
      <React.StrictMode>
        <SearchField enginePromise={enginePromise} />
      </React.StrictMode>
    )
  }
}
customElements.define('adc-headless-search-field', StandaloneSearchField)

class StandaloneSearchPage extends ComponentAsCustomElement {
  mount() {
    const searchPageElement = document.getElementById('adc-headless-search-page')
    const root = ReactDOM.createRoot(searchPageElement as HTMLElement)
    root.render(
      <React.StrictMode>
        <SearchPage enginePromise={enginePromise} />
      </React.StrictMode>
    )
  }
}
customElements.define('adc-headless-search-page', StandaloneSearchPage)

// Replace elements
if (!IS_DEV && window.lumapps.customize) {
  // Replace search page
  window.lumapps.customize(({ targets, components, render, placement }: any) => {
    const { RawHTML } = components
    render({
      target: targets.SEARCH,
      placement: placement.REPLACE,
      toRender: RawHTML({
        html: '<adc-headless-search-page id="adc-headless-search-page"></adc-headless-search-page>',
      }),
    })
  })

  // Replace search box
  window.lumapps.customize(({ targets, components, render, placement }: any) => {
    const { RawHTML } = components
    render({
      target: targets.SEARCH_BOX,
      placement: placement.LEFT,
      toRender: RawHTML({
        html: '<adc-headless-search-field id="adc-headless-search-field"></adc-headless-search-field>',
      }),
    })
  })
  window.lumapps.disable('search-box')
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
