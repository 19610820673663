import { type TRaw, type TResult } from '../../types'
import { getUserFullname } from '../../utils/getUserFullname'
import UserCardTrigger from '../UserCardTrigger'

export type ResultAuthorProps = {
  result: TResult
}
export default function ResultAuthor({ result }: ResultAuthorProps) {
  const { lumapps_author_id, lumapps_author_email } = result.raw as TRaw

  if (!lumapps_author_id) {
    return null
  }

  return (
    <div className="flex items-center">
      <UserCardTrigger
        fullName={getUserFullname(result.raw as TRaw)}
        email={lumapps_author_email}
        id={lumapps_author_id}
      />
    </div>
  )
}
