import React from 'react'

import { ReactComponent as BackslashIcon } from '../icons/svg/circle-backslash.svg'

export type WarningSectionProps = {
  title: string
  children?: React.ReactNode
  IconComponent?: typeof BackslashIcon
}
export default function WarningSection({
  title,
  children,
  IconComponent = BackslashIcon,
}: WarningSectionProps) {
  return (
    <div className="flex flex-col justify-center items-center text-gray-600">
      <IconComponent className="p-2 bg-gray-100 rounded-full text-ternary" width="50" height="50" />
      <h2 className="mt-2 mb-14 text-te">{title}</h2>
      {children ? <div className="text-gray-900">{children}</div> : null}
    </div>
  )
}
