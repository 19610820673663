import React from 'react'
import { SearchEngine } from '@coveo/headless'

import Results from '../Results/Results'
import { buildFacets } from '../SideFacets/buildFacets'
import SearchPagePanel from './SearchPagePanel'
import { useSearchPage } from './useSearchPage'
import NoResults from '../NoResults'
import { Pagination } from '../Pagination'
// import { useInitialState } from './useInitialState'
import { useIsFullLoading } from '../../hooks/useIsFullLoading'
import { useUrlManager } from './useUrlManager'

export interface SearchPageProps {
  engine: SearchEngine
}
export default function SearchPage({ engine }: SearchPageProps) {
  useUrlManager(engine)
  const { querySummary, facets, resultList, pager, sort, status } = useSearchPage(
    engine,
    buildFacets
  )
  const isFullLoading = useIsFullLoading(querySummary)

  return (
    <SearchPagePanel
      tabId="all"
      facets={facets}
      querySummary={querySummary}
      sort={sort}
      status={status.state}
    >
      {querySummary.hasResults && !querySummary.isLoading ? (
        <Results results={resultList.results} engine={engine} />
      ) : (
        !status.state.isLoading &&
        status.state.firstSearchExecuted && <NoResults query={querySummary.query} />
      )}

      {querySummary.hasResults && !isFullLoading && resultList.moreResultsAvailable && (
        <Pagination pager={pager} nbOfResults={querySummary.total} className="mt-16" />
      )}
    </SearchPagePanel>
  )
}
