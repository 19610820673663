import { CUSTOMER_SLUG, INSTANCE_SLUG, IS_DEV, ADC_CONFIG } from '../constants'

export const createSearchUrl = (query: string) => {
  if (IS_DEV) {
    return `/?q=${encodeURIComponent(query)}`
  }

  if (ADC_CONFIG?.useVanityUrl) {
    return `/${INSTANCE_SLUG}/search/${encodeURIComponent(query)}/?q=${encodeURIComponent(query)}`
  }

  return `/a/${CUSTOMER_SLUG}/${INSTANCE_SLUG}/search/${encodeURIComponent(query)}/?q=${encodeURIComponent(query)}`
}
