import React from 'react'
import { CUSTOMER_ID, IS_DEV } from '../constants'
import { twMerge } from 'tailwind-merge'

export type AvatarProps = {
  userId: string
  boxSize?: string
  className?: string
}
export default function Avatar({ userId, boxSize = '24', className }: AvatarProps) {
  const imgSrc = React.useMemo(() => {
    if (IS_DEV) {
      return 'https://picsum.photos/id/237/200/300'
      // return 'https://dev.cdn.lumapps.com/669552c198/assets/img/default-avatar/default-avatar-128.jpg'
    }

    return `/serve/profilepicture/${CUSTOMER_ID}/${userId}`
  }, [userId])

  if (!userId) {
    return null
  }

  return (
    <img
      src={imgSrc}
      height={boxSize}
      width={boxSize}
      className={twMerge('rounded-full', className)}
      alt=""
      loading="lazy"
    />
  )
}
