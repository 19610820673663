export abstract class ComponentAsCustomElement extends HTMLElement {
  observer: MutationObserver

  constructor() {
    super()
    this.observer = new MutationObserver(() => this.update())
    this.observer.observe(this, { attributes: true })
  }

  update() {
    this.unmount()
    this.mount()
  }

  connectedCallback() {
    this.mount()
  }

  disconnectedCallback() {
    this.unmount()
    this.observer.disconnect()
  }

  abstract mount(): any

  unmount() {
    // ReactDOM.hydrateRoot(this);
  }
  // abstract getProps(attributes: any): any;
}
