import { QUERY_PARAMS_KEYS } from '../constants'
import { getQueryParam } from './queryParam'
import { getFirstGroup } from './regexp'

export const getSearchQueryFromUrl = () => {
  const regexp = /^https:\/\/.*\/[a-zA-Z-]+\/search\/([a-zA-Z]*)\/?.*\/?/g
  const res = getFirstGroup(regexp, window.location.href)

  const query = getQueryParam(QUERY_PARAMS_KEYS.query)

  if (query) {
    return query
  }

  return res && res.length > 0 ? res[0] : ''
}
