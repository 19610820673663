import { SearchBoxState, SearchEngine, buildSearchBox } from '@coveo/headless'
import React from 'react'

export const useSearchBox = (engine: SearchEngine) => {
  const searchboxCtrl = React.useMemo(() => {
    return buildSearchBox(engine, {
      options: {
        numberOfSuggestions: 0,
      },
    })
  }, [])
  const [state, setState] = React.useState<SearchBoxState>(searchboxCtrl.state)

  React.useEffect(() => {
    searchboxCtrl.subscribe(() => setState(searchboxCtrl.state))
  }, [])

  return { controller: searchboxCtrl, state }
}
